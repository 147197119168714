import { CssBaseline } from '@material-ui/core';
import React from 'react';
import Dashboard from './Components/Dashboard';
function App() {
  return (
    <main>
      {/* <CssBaseline> */}
        <Dashboard />
      {/* </CssBaseline> */}
    </main>

  );
}

export default App;
